<template>
    <v-data-table :items-per-page="5" :headers="headers" :items="callbacksList"
        class="elevation-0 scrollTable">
        <template v-slot:item.sent="{ item }">

            <v-tooltip top color="">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on"
                        :class="[setColorLine(item.sent, item.send_error)[1], 'pa-1 rounded']">
                        <v-icon class="white--text font-weight-medium">{{ item.sent ? 'call_made' :
                            'call_received'
                            }}</v-icon>
                    </div>
                </template>
                <span class="">{{ item.sent ? 'Envio' : 'Resposta' }}</span>
            </v-tooltip>

        </template>
        <template v-slot:item.send_date="{ item }">
            <div :class="setColorLine(item.sent, item.send_error)[2]">{{ (item.send_date ?
                item.send_date.split(" ")[0] : item.receipt_date.split(" ")[0]) |
                formatDate }}<span><v-icon class="ml-3 mb-1 font-weight-black" x-small>schedule</v-icon>
                    {{ item.send_date ? item.send_date.split(" ")[1] : item.receipt_date.split(" ")[1]
                    }}</span></div>
        </template>
        <template v-slot:item.message="{ item }">
            <div :class="setColorLine(item.sent, item.send_error)[2]">
                {{ item.message }}
            </div>
        </template>
        <template v-slot:item.status="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon :color="formatStatus(item.status)[2]" center
                        v-bind="attrs" v-on="on">
                        {{ formatStatus(item.status)[1] }}
                    </v-icon>
                </template>
                <span class="miniTtip" v-html="formatStatus(item.status)[0]"></span>
            </v-tooltip>
        </template>
    </v-data-table>
</template>

<script>
export default {
    methods: {
        formatStatus(status) {
            let st = parseInt(status)
            switch (st) {
                case 0:
                    return ["Não finalizado", "highlight_off", "red"];
                case 1:
                    return ["Autorizado", "verified_user", "success"];
                case 2:
                    return ["Confirmado", "thumb_up_alt", "primary"];
                case 3:
                    return ["Não autorizado", "announcement", "red darken-3"];
                case 12:
                    return ["Aguardando Retorno", "hourglass_top", "warning"];
                default:
                    return ["Cancelado", "block", "grey darken-1"];
            }
        },
        setColorLine(sent, err) {
            if (err) {
                return ['close', 'red lighten-2', 'red--text text--lighten-2']
            }
            if (sent) {
                return ['call_made', 'indigo lighten-2', 'indigo--text']
            } else {
                return ['call_received', 'teal lighten-2', 'teal--text']
            }
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Ação",
                    value: "sent",
                    sortable: false,
                    align: "center",
                    width: 55
                },
                {
                    text: "Data do evento",
                    value: "send_date",
                    sortable: false,
                    // align: "center",
                    width: 200,
                },

                {
                    text: "Mensagem",
                    value: "message",
                    sortable: false,
                    // width: 450,
                },
                {
                    text: "Status da transação",
                    value: "status",
                    sortable: false,
                    align: "center",
                    width: 170,
                    // class: "minWidthCell"
                },
            ],
        }
    },
    props: {
        callbacksList: Array,
    },
}
</script>

<style lang="scss" scoped></style>