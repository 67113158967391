<template>
  <v-dialog v-model="batchDetailDialog" scrollable max-width="960px">
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 mb-3"
        ><v-app-bar height="50" flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            {{ batchName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn
            @click="toogleBatchDetailDilog('')"
            class="mt-6"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar></v-card-title
      >
      <v-card-title class="px-7"> Lista de boletos do lote</v-card-title>
      <v-card-text class="pt-3">
        <v-data-table
          :headers="headers"
          :items="billets"
          class="elevation-0 scrollTable"
        >
          <template v-slot:item.dataEmissao="{ item }">
            {{ item.dataEmissao | formatDate }}
          </template>
          <template v-slot:item.dataVencimento="{ item }">
            {{ item.dataVencimento | formatDate }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="setStatusBillet(item.status)[0]"
                  center
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ setStatusBillet(item.status)[1] }}
                </v-icon>
              </template>
              <span class="miniTtip">{{
                setStatusBillet(item.status)[2]
              }}</span>
            </v-tooltip>
            <!-- <div v-html="setStatusBillet(item.status)"></div> -->
          </template>
          <template v-slot:item.data_title.pagador.nome="{ item }">
            {{ item.data_title.pagador.nome || "Não identificado" }}
          </template>
          <template v-slot:item.value="{ item }">
            R$ {{ item.value | formatPrice }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleBatchDetailDilog('')">
          Fechar
        </v-btn>
        <!-- <v-btn color="green" text @click="sendBatch"> Salvar </v-btn> -->
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
export default {
  methods: {
    setStatusBillet(data) {
      //console.log("fff");
      let status = {
        "Baixando/Cancelando": [
          "grey darken-1",
          "warning",
          "Aguardando cancelamento",
        ],
        Andamento: ["orange darken-2", "query_builder", "Aguardando registro"],
        Aberto: ["blue darken-1", "verified", "Boleto registrado"],
        Liquidado: ["green darken-3", "attach_money", "Boleto liquidado"],
        "Falha no Registro": [
          "red darken-4",
          "new_releases",
          "Falha ao registrar boleto",
        ],
        "Falha na Baixa/Cancelamento": [
          "red darken-4",
          "new_releases",
          "Falha no cancelamento",
        ],
        Vencido: ["red lighten-1", "event_busy", "Boleto vencido"],
      };
      return status[data];
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Status",
          value: "status",
          sortable: false,
          sortable: true,
        },
        { text: "Sacado", value: "data_title.pagador.nome", sortable: true },
        {
          text: "Emissão",
          value: "dataEmissao",
          sortable: false,
          align: "center",
        },
        {
          text: "Vencimento",
          sortable: false,
          value: "dataVencimento",
          align: "center",
        },
        { text: "Valor", value: "value", sortable: false, align: "right" },
      ],
    };
  },
  props: {
    billets: Array,
    toogleBatchDetailDilog: Function,
    batchDetailDialog: Boolean,
    batchName: String,
  },
};
</script>

<style>
</style>