<template>
  <v-dialog
    scrollable
    light
    v-model="logErrorDeatilDilog"
    persistent
    max-width="900px"
  >
    <v-card>
      <v-card-title class="headline px-2">
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0">
            {{ propsTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn
            @click="toogleLogErrorDeatilDilog"
            class="mt-2"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar>
      </v-card-title>
      <v-card-text class="pb-0">
        <h4 class="mb-2 error--text">{{ propsSubTitle }}</h4>
        <pre
          style="overflow: auto"
          class="black green--text text--accent-3"
          v-if="jsonError"
        ><code>{{ jsonError }}</code></pre>
        <v-container class="px-0" fluid v-if="stringError">
          <v-textarea
            name="input-7-1"
            filled
            label=""
            auto-grow
            :value="stringError"
            readonly
          ></v-textarea>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleLogErrorDeatilDilog">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    toogleLogErrorDeatilDilog: Function,
    logErrorDeatilDilog: Boolean,
    jsonError: String,
    stringError: String,
    propsSubTitle: String,
    propsTitle: String,
  },
};
</script>

<style>
</style>