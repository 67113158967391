var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"960px"},model:{value:(_vm.batchDetailDialog),callback:function ($$v) {_vm.batchDetailDialog=$$v},expression:"batchDetailDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-2 mb-3",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"height":"50","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0 pt-0"},[_vm._v(" "+_vm._s(_vm.batchName)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-6",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":function($event){return _vm.toogleBatchDetailDilog('')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-title',{staticClass:"px-7"},[_vm._v(" Lista de boletos do lote")]),_c('v-card-text',{staticClass:"pt-3"},[_c('v-data-table',{staticClass:"elevation-0 scrollTable",attrs:{"headers":_vm.headers,"items":_vm.billets},scopedSlots:_vm._u([{key:"item.dataEmissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataEmissao))+" ")]}},{key:"item.dataVencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.dataVencimento))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.setStatusBillet(item.status)[0],"center":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.setStatusBillet(item.status)[1])+" ")])]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(_vm.setStatusBillet(item.status)[2]))])])]}},{key:"item.data_title.pagador.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_title.pagador.nome || "Não identificado")+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatPrice")(item.value))+" ")]}}])})],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.toogleBatchDetailDilog('')}}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }