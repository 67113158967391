<template>
    <v-row class="px-3 py-5">
        <v-col class="pl-5" cols="6">
            <v-text-field v-model="editDataBillet.nome" label="Sacado" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5" cols="6">
            <v-text-field v-model="editDataBillet.email" label="E-mail" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-text-field-simplemask class="" v-model="editDataBillet.numeroInscricao"
                v-bind:label="editDataBillet.tipoInscricao == 1 ? 'CPF' : 'CNPJ'" v-bind:properties="{
                    rules: [],
                    prefix: '',
                    suffix: '',
                    readonly: true,
                    disabled: false,
                    outlined: false,
                    clearable: false,
                    placeholder: '',
                }" v-bind:options="{
                    inputMask:
                        (editDataBillet.numeroInscricao
                            ? editDataBillet.numeroInscricao.length
                            : '') <= 11
                            ? '###.###.###-########'
                            : '##.###.###/####-##',
                    outputMask: '##############',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: true,
                    lowerCase: false,
                }" />
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-text-field v-model="editDataBillet.source" label="Reigistro via" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-text-field v-model="editDataBillet.title_number" label="Número do boleto" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5" cols="8">
            <v-text-field v-model="editDataBillet.transaction_code" label="Código da transação" readonly></v-text-field>
        </v-col>

        <v-col class="pl-5" cols="4">
            <v-select :disabled="editDataBillet.codigoTipoTitulo ? false : true" readonly class=""
                v-model="editDataBillet.codigoTipoTitulo" :items="$store.state.billetsCbos.registrationTypes"
                label="Tipo do título" item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-select :disabled="editDataBillet.codigoAceite ? false : true" readonly class=""
                v-model="editDataBillet.codigoAceite" :items="$store.state.billetsCbos.aceite"
                label="Definição de aceite" item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-text-field :disabled="editDataBillet.numeroTituloBeneficiario ? false : true" readonly class=""
                v-model="editDataBillet.numeroTituloBeneficiario" label="Título do beneficiário"></v-text-field>
        </v-col>
        <v-col class="pl-5 pb-0" cols="4">
            <v-text-field v-model="dataEmissaoFormated" label="Data de emissão" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-text-field :disabled="dataVencimentoFormated ? false : true" v-model="dataVencimentoFormated"
                label="Data do vencimento" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pb-0" cols="4">
            <v-text-field :disabled="payment_date_formatted ? false : true" v-model="payment_date_formatted"
                label="Data do pagamento" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pb-0" cols="4"></v-col>
        <v-col class="pl-5 colBoxLeft" cols="4">
            <v-textarea readonly :disabled="editDataBillet.linha_descritiva1 ? false : true" maxlength="75" counter="75"
                rows="1" class="" v-model="editDataBillet.linha_descritiva1" label="Linha de descritiva 01"
                hint="Linha 1 para informaçoes adicionais" persistent-hint></v-textarea>
        </v-col>
        <v-col class="pl-5 colBoxRight" cols="2">
            <v-select readonly :disabled="editDataBillet.pos_linha1 ? false : true" class="" 
                v-model="editDataBillet.pos_linha1" :items="positionsLines" label="Posição" item-text="text"
                item-value="id" hint="Define onde a linha deve aparecer" persistent-hint></v-select>
        </v-col>
        <v-col class="pl-5 colBoxLeft" cols="4">
            <v-textarea readonly :disabled="editDataBillet.linha_descritiva2 ? false : true" maxlength="75" counter="75"
                rows="1" class="" v-model="editDataBillet.linha_descritiva2" label="Linha de descritiva 02"
                hint="Linha 2 para informaçoes adicionais" persistent-hint></v-textarea>
        </v-col>
        <v-col class="pl-5 colBoxRight" cols="2">
            <v-select readonly :disabled="editDataBillet.pos_linha2 ? false : true" class="" 
                v-model="editDataBillet.pos_linha2" :items="positionsLines" label="Posição" item-text="text"
                item-value="id" hint="Define onde a linha deve aparecer" persistent-hint></v-select>
        </v-col>
        <v-col class="pl-5 colBoxLeft" cols="4">
            <v-textarea readonly :disabled="editDataBillet.linha_descritiva3 ? false : true" maxlength="75" counter="75"
                rows="1" class="" v-model="editDataBillet.linha_descritiva3" label="Linha de descritiva 03"
                hint="Linha 3 para informaçoes adicionais" persistent-hint></v-textarea>
        </v-col>
        <v-col class="pl-5 colBoxRight" cols="2">

            <v-select readonly :disabled="editDataBillet.pos_linha3 ? false : true" class="" 
                v-model="editDataBillet.pos_linha3" :items="positionsLines" label="Posição" item-text="text"
                item-value="id" hint="Define onde a linha deve aparecer" persistent-hint></v-select>
        </v-col>

        <v-col class="pl-6" cols="6">
            <v-textarea readonly :disabled="editDataBillet.descricaoTipoTitulo ? false : true" rows="1" type="" class=""
                v-model="editDataBillet.descricaoTipoTitulo" label="Descrição sobre o Tipo do título"></v-textarea>
        </v-col>
    </v-row>
</template>

<script>
import * as moment from "moment";
export default {
    methods: {
        copyedLine() {
            this.copyed = 'Copiada!'
            setTimeout(() => {
                this.copyed = 'Copiar linha digitável'
            }, 2500);
        },
        copyDigLine(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            let _this = this;
            navigator.clipboard.writeText(text).then(
                function () {
                    _this.reveal = true
                    setTimeout(() => {
                        _this.reveal = false
                    }, 2500);
                },
                function (err) {
                    console.error("Async: Could not copy text: ", err);
                }
            );
            this.copyedLine()
        },
    },
    data() {
        return {
            positionsLines: [
                { id: 1, text: "Recibo do pagador" },
                { id: 2, text: "Ficha de compensação" },
                { id: 3, text: "Ambos" },
                { id: 4, text: "Não visualizar no boleto" },
            ],
            copyed: "Copiar linha digitável",
            payment_date: ""
        }
    },
    computed: {
        payment_date_formatted: {
            get: function () {
                let date = this.editDataBillet.payment_date
                    ? moment(this.editDataBillet.payment_date).format("DD/MM/YYYY")
                    : "";
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                let date = moment(this.editDataBillet.payment_date).format("DD/MM/YYYY");
                // console.log("aaaa", newValue);
                if (date == "Invalid date") {
                    return null;
                }
                if (!newValue) {
                    this.editDataBillet.payment_date = newValue;
                }
                return newValue;
            },
        },
        dataVencimentoFormated: {
            get: function () {
                let date = this.editDataBillet.dataVencimento
                    ? moment(this.editDataBillet.dataVencimento).format("DD/MM/YYYY")
                    : "";
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                let date = moment(this.editDataBillet.dataVencimento).format("DD/MM/YYYY");
                // console.log("aaaa", newValue);
                if (date == "Invalid date") {
                    return null;
                }
                if (!newValue) {
                    this.editDataBillet.dataVencimento = newValue;
                }
                return newValue;
            },
        },
        dataEmissaoFormated: {
            get: function () {
                let date = this.editDataBillet.dataEmissao
                    ? moment(this.editDataBillet.dataEmissao).format("DD/MM/YYYY")
                    : "";
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                let date = moment(this.editDataBillet.dataEmissao).format("DD/MM/YYYY");
                // console.log("aaaa", newValue);
                if (date == "Invalid date") {
                    return null;
                }
                if (!newValue) {
                    this.editDataBillet.dataEmissao = newValue;
                }
                return newValue;
            },
        },
    },
    props: {
        editDataBillet: Object
    },
}

</script>

<style></style>