var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1280px","persistent":"","transition":"dialog-top-transition"},model:{value:(_vm.listBatchsDialog),callback:function ($$v) {_vm.listBatchsDialog=$$v},expression:"listBatchsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-2 mb-5",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"height":"50","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0 pt-0"},[_vm._v(" Gerenciamento de lotes enviados ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-6",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleStatusBatchDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-title',{staticClass:"pt-5 pb-3 px-10 blue-grey--text text--darken-2"},[_c('v-container',{staticClass:"pb-4",attrs:{"fluid":""}},[_c('v-row',{},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Nome do lote"},model:{value:(_vm.batch),callback:function ($$v) {_vm.batch=$$v},expression:"batch"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.getBatchs()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" search ")])],1)]}}])},[_c('span',[_vm._v("Procurar pelo nome do lote")])])],1)],1),_c('v-row',{staticClass:"mb-7 mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-2 grey--text text--darken-2",staticStyle:{"word-break":"normal"}},[_vm._v(" Procure lotes por nome e acompanhe a situação do processamento de registro deles. ")])])],1)],1)],1),_c('div',{staticClass:"px-12"},[_c('v-data-table',{staticClass:"elevation-0 scrollTable",attrs:{"headers":_vm.headers,"items":_vm.batchs},scopedSlots:_vm._u([{key:"item.titles_processeds",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"pl-1"},[_c('v-progress-circular',_vm._g(_vm._b({attrs:{"rotate":360,"size":40,"width":7,"value":_vm.buildTotal(item)[0],"color":_vm.buildTotal(item)[1]}},'v-progress-circular',attrs,false),on),[_vm._v(" "+_vm._s(_vm.buildTotal(item)[0] == 100 ? "✔" : item.titles_processeds)+" ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(_vm.buildTotal(item)[2])+" ")])])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"px-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at.substring(0, 10)))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-1",attrs:{"x-small":"","color":"teal darken-2","dark":""},on:{"click":function($event){return _vm.getBatchById(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" remove_red_eye ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v("Ver detalhes do lote")])])]}}])})],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleStatusBatchDialog}},[_vm._v(" Fechar ")])],1)],1)],1),_c('BatchDetails',{attrs:{"billets":_vm.billets,"batchName":_vm.batchName,"batchDetailDialog":_vm.batchDetailDialog,"toogleBatchDetailDilog":_vm.toogleBatchDetailDilog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }