var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 scrollTable",attrs:{"items-per-page":5,"headers":_vm.headers,"items":_vm.callbacksList},scopedSlots:_vm._u([{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[_vm.setColorLine(item.sent, item.send_error)[1], 'pa-1 rounded']},'div',attrs,false),on),[_c('v-icon',{staticClass:"white--text font-weight-medium"},[_vm._v(_vm._s(item.sent ? 'call_made' : 'call_received'))])],1)]}}],null,true)},[_c('span',{},[_vm._v(_vm._s(item.sent ? 'Envio' : 'Resposta'))])])]}},{key:"item.send_date",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.setColorLine(item.sent, item.send_error)[2]},[_vm._v(_vm._s(_vm._f("formatDate")((item.send_date ? item.send_date.split(" ")[0] : item.receipt_date.split(" ")[0])))),_c('span',[_c('v-icon',{staticClass:"ml-3 mb-1 font-weight-black",attrs:{"x-small":""}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(item.send_date ? item.send_date.split(" ")[1] : item.receipt_date.split(" ")[1]))],1)])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.setColorLine(item.sent, item.send_error)[2]},[_vm._v(" "+_vm._s(item.message)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.formatStatus(item.status)[2],"center":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatStatus(item.status)[1])+" ")])]}}],null,true)},[_c('span',{staticClass:"miniTtip",domProps:{"innerHTML":_vm._s(_vm.formatStatus(item.status)[0])}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }