var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1600px"},model:{value:(_vm.billetsLogDilog),callback:function ($$v) {_vm.billetsLogDilog=$$v},expression:"billetsLogDilog"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-2 pb-5",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"height":"50","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0 pt-0"},[_vm._v(" Logs de transações de boletos ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-7",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleBilletsLogDilog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('LogFilters',{attrs:{"billetsLogDilog":_vm.billetsLogDilog,"getLogs":_vm.getLogs,"perPage":_vm.perPage}}),_c('LogErrorDeatil',{attrs:{"logErrorDeatilDilog":_vm.logErrorDeatilDilog,"toogleLogErrorDeatilDilog":_vm.toogleLogErrorDeatilDilog,"jsonError":_vm.jsonError,"stringError":_vm.stringError,"propsSubTitle":"Informações registradas","propsTitle":_vm.propsTitle}}),_c('v-data-table',{staticClass:"emptyHeight elevation-0 opacityTable mx-15",attrs:{"no-data-text":"Utilize os filtros acima e faça sua pesquisa","height":_vm.logsList.length >= 2 ? '' : '250',"page":1,"itemsPerPage":_vm.perPage,"hide-default-footer":"","light":"","headers":_vm.headers,"items":_vm.logsList},scopedSlots:_vm._u([{key:"item.sacado",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.sacado)+" ")]),_c('div',{staticClass:"caption font-weight-medium"},[_vm._v(" "+_vm._s(item.email_sacado)+" ")])]}},{key:"item.description.message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-2"},[_c('small',{staticClass:"mr-1"},[_vm._v("Msg:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.description.message))])]),_c('div',{staticClass:"mt-1 mb-2"},[_c('small',{staticClass:"mr-1"},[_vm._v("Cod:")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.transaction_code))])])]}},{key:"item.event",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(item.event.split(" ")[0])))]),_c('div',{staticClass:"caption"},[_c('v-icon',{staticClass:"mb-1 font-weight-black",attrs:{"x-small":""}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(item.event.split(" ")[1])+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btMicro px-1 mr-3",attrs:{"x-small":"","color":item.type == 'ERROR' ? 'error' : 'primary',"dark":""},on:{"click":function($event){return _vm.getMoreDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","x-small":""}},[_vm._v(" error_outline ")])],1)]}}],null,true)},[_c('span',{staticClass:"miniTtip"},[_vm._v(_vm._s(item.type == "ERROR" ? "Detalhes do erro" : "Ver mais detalhes"))])])]}}])}),_c('v-row',{staticClass:"mx-15 d-flex flex-row-reverse bordTop"},[_c('v-col',{staticClass:"d-flex pr-0",staticStyle:{"max-width":"75px"},attrs:{"cols":"12","sm":"1"}},[_c('v-select',{attrs:{"items":[5, 10, 50, 100]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('v-col',{staticClass:"pr-1 mb-7 align-end d-flex justify-end col-sm-2 col-12",staticStyle:{"max-width":"110px"},attrs:{"cols":"12","sm":"2"}},[_c('span',{staticClass:"caption"},[_vm._v("Itens por página")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-container',{staticClass:"d-flex flex-row justify-end max-width pr-0"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":8},model:{value:(_vm.actualPage),callback:function ($$v) {_vm.actualPage=$$v},expression:"actualPage"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleBilletsLogDilog}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }