<template>
  <v-container fluid class="mt-3">
    <div class="mb-1 pl-8 pr-1 mr-8 d-flex flex-row pt-3">
      <v-btn
        outlined
        color="primary"
        @click="confirmSendBilletsByEmail"
        :disabled="sendSelectedsBillets"
      >
        <v-icon left> mail_outline </v-icon> Enviar selecionados
      </v-btn>
      <v-btn
        @click="confirmDeletePaidBillets"
        outlined
        color="green darken-3"
        :disabled="deleteSelectedsBillets"
        class="ml-4"
      >
        <v-icon left> delete_outline </v-icon> Excluir Liquidados
      </v-btn>
      <v-btn
        @click="toogleRegisterBilletDilog"
        class="ml-auto"
        outlined
        color="primary"
      >
        <v-icon left> add_circle </v-icon> Novo boleto
      </v-btn>
      <v-menu transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" class="ml-4" outlined color="green darken-3">
            <v-icon left> drive_folder_upload </v-icon> Gerenciar lotes
          </v-btn>
        </template>
        <v-list class="menuLote">
          <v-list-item @click="toogleImportBatchDilog">
            <v-list-item-icon>
              <v-icon v-text="'playlist_add'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="'Novo lote'"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toogleStatusBatchDialog">
            <v-list-item-icon>
              <v-icon v-text="'rotate_right'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Status dos lotes'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-container>
</template>

<script>
export default {
  methods: {
    confirmDeletePaidBillets() {
      let obj = {
        message: `Você deseja excluir os boletos selecionados?`,
        title: "Excluir boletos",
        callback: (_) => {
          this.deletePaidBillets();
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      // console.log("boleto",data)
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    deletePaidBillets() {
      let ids = this.selectedsBillets.map((billet) => {
        return billet.id;
      });
      let obj = {
        url: "",
        query: {
          idsToSend: ids,
        },
        method: "post",
      };
      console.log("lista ids", obj.query);
      return;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) return;

        let opts = {
          message: `Boletos excluídos com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    confirmSendBilletsByEmail() {
      let obj = {
        message: `Você deseja enviar por e-mail os boletos selecionados?`,
        title: "Enviar boletos",
        callback: (_) => {
          this.sendBilletsByEmail();
        },
        type: "warning",
        btConfirm: { name: "Sim", show: true },
        btCancel: { name: "Não", show: true },
      };
      // console.log("boleto",data)
      this.$store.commit("confirmMessage/showConfirmMessage", obj);
    },
    sendBilletsByEmail() {
      let ids = this.selectedsBillets.map((billet) => {
        return billet.id;
      });
      let obj = {
        url: "",
        query: {
          idsToSend: ids,
        },
        method: "post",
      };
      console.log("lista ids", obj.query);
      return;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) return;
        let opts = {
          message: `Boletos enviados com sucesso.`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
    vrifySelectedsBillets(list, status) {
      // console.log("stating");
      let btDisabled = list.filter((billet) => {
        return billet.status != status;
      });
      if (!list.length || btDisabled.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selectedsBillets(list) {
      // console.log("sel", list);
      this.sendSelectedsBillets = this.vrifySelectedsBillets(list, "Aberto");
      this.deleteSelectedsBillets = this.vrifySelectedsBillets(
        list,
        "Liquidado"
      );
    },
  },
  data() {
    return {
      sendSelectedsBillets: true,
      deleteSelectedsBillets: true,
    };
  },
  props: {
    selectedsBillets: Array,
    toogleRegisterBilletDilog: Function,
    toogleImportBatchDilog: Function,
    toogleStatusBatchDialog: Function,
  },
};
</script>

<style>
.menuLote .v-list-item__icon {
  margin-right: 15px !important;
}
</style>