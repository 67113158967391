<template>
  <v-dialog v-model="importBatchDialog" scrollable max-width="680px">
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 mb-3"
        ><v-app-bar height="50" flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            Importar lote
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn
            @click="toogleImportBatchDilog"
            class="mt-6"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar></v-card-title
      >
      <v-card-text class="pt-3">
        <v-row class="px-3">
          <v-col class="pl-4" cols="8">
            <h3 class="mb-2">Arquivo exemplo</h3>
            <p class="mb-1 caption grey--text text--darken-3">
              Faça o download do arquivo de exemplo ao lado para saber como
              preencher os campos e enviar boletos em lote.
            </p>
            <p class="mb-0 caption grey--text text--darken-3">
              Seguindo o arquivo de exemplo você poderá gerar inúmeros boletos
              de uma só vez.
            </p>
          </v-col>
          <v-col class="pl-4 pt-12" cols="4">
            <v-chip
              @click="(_) => {}"
              class="pr-3"
              color="blue--text blue lighten-5 pl-4 pr-5"
            >
              <v-icon left small> file_download </v-icon>
              <span class="" style="padding-bottom: 2px">
                exemplo.csv</span
              >
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pt-0">
        <v-form ref="importBatchForm" style="width: 100%">
          <v-row class="px-3">
            <v-col class="pl-5" cols="6">
              <v-text-field
                clearable
                class="requiredField"
                :rules="$store.state.formRules.required"
                v-model="formData.name"
                label="Nome do lote"
              ></v-text-field>
              <!-- hint="Número do convênio"
                persistent-hint -->
            </v-col>
            <v-col class="pl-5" cols="6">
              <!-- hint="Digite o nome do usuário"
                persistent-hint -->
              <v-select
                class="requiredField"
                :rules="$store.state.formRules.required"
                clearable
                v-model="formData.profile_id"
                :items="profilesCbos"
                label="Selecione umprefil"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-col>
            <v-col class="pl-5" cols="12">
              <!-- @change="uploadCsv" -->
              <v-file-input
                class="requiredField"
                :rules="$store.state.formRules.required"
                v-model="formData.titles_csv"
                accept=".csv"
                label="File input"
              ></v-file-input>
            </v-col> </v-row
        ></v-form>
      </v-card-text>

      <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleImportBatchDilog"> Fechar </v-btn>
        <v-btn color="green" text @click="sendBatch"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const resetImportBatchForm = {
  source: "web",
  profile_id: "",
  name: "",
  titles_csv: null,
};
export default {
  watch: {
    importBatchDialog(v) {
      if (!v) {
        this.$refs.importBatchForm.resetValidation();
      }
    },
  },
  methods: {
    sendBatch() {
      if (!this.$refs.importBatchForm.validate()) {
        return;
      }
      let formDataQuery = new FormData();
      formDataQuery.append("titles_csv", this.formData.titles_csv);
      formDataQuery.append("source", this.formData.source);
      formDataQuery.append("profile_name", this.formData.profile_id);
      formDataQuery.append("name", this.formData.name);
      var obj = {
        url: "/api/v1/user/batch/create",
        query: formDataQuery,
        method: "post",
        multipart: true,
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        // console.log("Lote", response.data);
        this.formData = { ...resetImportBatchForm };
        this.toogleImportBatchDilog();
        setTimeout(() => {
          this.getBillets(this.$store.state.lastBilletsParans, false);
        }, 100);
      });
    },
    uploadCsv: function () {
      let _this = this;
      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        _this.csvFileSrc = e.target.result;
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
  },
  data() {
    return {
      formData: { ...resetImportBatchForm },
      csvFileSrc: null,
    };
  },
  props: {
    getBillets: Function,
    profilesCbos: Array,
    toogleImportBatchDilog: Function,
    importBatchDialog: Boolean,
  },
};
</script>

<style>
</style>