<template>
    <v-dialog v-model="callbackHistoryDialog" scrollable persistent max-width="1440px">
        <v-card min-height="465">
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2"><v-app-bar height="50" flat
                    color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        Histórico de Callbacks
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleCallbackHistoryList" class="mt-6" color="red" fab icon dark x-small absolute
                        top right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar>
            </v-card-title>
            <div class="px-16 mt-8 mx-4">
                <h3 class="mb-2 grey--text text--darken-2 font-weight-medium">Lista de callbacks executados</h3>
                <p class="mb-8 body-2 grey--text text--darken-3">
                    Acompanhe todo o histórico de callbacks da transção de código - <b>{{
                        transactionCode }}</b>
                </p>
            </div>
            <v-card-text class="px-16">
                <TableCreditCard v-if="creditCard ? true : false" :callbacksList="callbacksList" />
                <v-data-table v-if="creditCard ? false : true" :items-per-page="5" :headers="headers"
                    :items="callbacksList" class="elevation-0 scrollTable">
                    <template v-slot:item.sent="{ item }">

                        <v-tooltip top color="">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on"
                                    :class="[setColorLine(item.sent, item.send_error)[1], 'pa-1 rounded']">
                                    <v-icon class="white--text font-weight-medium">{{ item.sent ? 'call_made' :
                                        'call_received'
                                        }}</v-icon>
                                </div>
                            </template>
                            <span class="">{{ item.sent ? 'Envio' : 'Resposta' }}</span>
                        </v-tooltip>

                    </template>
                    <template v-slot:item.send_date="{ item }">
                        <div :class="setColorLine(item.sent, item.send_error)[2]">{{ (item.send_date ?
                            item.send_date.split(" ")[0] : item.receipt_date.split(" ")[0]) |
                            formatDate }}<span><v-icon class="ml-3 mb-1 font-weight-black" x-small>schedule</v-icon>
                                {{ item.send_date ? item.send_date.split(" ")[1] : item.receipt_date.split(" ")[1]
                                }}</span></div>
                    </template>
                    <template v-slot:item.message="{ item }">
                        <div :class="setColorLine(item.sent, item.send_error)[2]">
                            {{ item.message }}
                        </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon @click="
                                    setStatusBillet(item.status)[2].indexOf('Falha') < 0
                                        ? null
                                        : confirmRetryRegisterBillet(item, $event)
                                    " :color="setStatusBillet(item.status)[0]" :class="setStatusBillet(item.status)[3]"
                                    center v-bind="attrs" v-on="on">
                                    {{ setStatusBillet(item.status)[1] }}
                                </v-icon>
                            </template>
                            <span class="miniTtip" v-html="setStatusBillet(item.status)[2]"></span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleCallbackHistoryList"> Fechar </v-btn>
                <!-- <v-btn color="green" text @click="sendBatch"> Salvar </v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
import TableCreditCard from "./CallbackHistoryCreditCardTable.vue"
export default {
    methods: {
        setColorLine(sent, err) {

            if (err) {
                return ['close', 'red lighten-2', 'red--text text--lighten-2']
            }
            if (sent) {
                return ['call_made', 'indigo lighten-2', 'indigo--text']
            } else {
                return ['call_received', 'teal lighten-2', 'teal--text']
            }
        },
        setStatusBillet(data) {
            //console.log("fff");
            let status = {
                "Baixado/Cancelado": [
                    "grey darken-1",
                    "block",
                    "Boleto cancelado",
                    "justIcon",
                ],
                "Baixando/Cancelando": [
                    "grey darken-1",
                    "warning",
                    "Aguardando cancelamento",
                    "justIcon",
                ],
                Andamento: [
                    "orange darken-2",
                    "query_builder",
                    "Aguardando registro",
                    "justIcon",
                ],
                Aberto: ["blue darken-1", "verified", "Boleto registrado", "justIcon"],
                Liquidado: [
                    "green darken-3",
                    "attach_money",
                    "Boleto liquidado",
                    "justIcon",
                ],
                "Falha no Registro": [
                    "red darken-4",
                    "error",
                    "Falha ao tentar registrar o boleto.",
                    "iconButton",
                ],
                "Falha na Baixa/Cancelamento": [
                    "red darken-4",
                    "error",
                    "Falha no cancelamento",
                    "iconButton",
                ],
                Vencido: ["red lighten-1", "event_busy", "Boleto vencido", "justIcon"],
            };
            return status[data];
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Ação",
                    value: "sent",
                    sortable: false,
                    align: "center",
                    width: 55
                },
                // {
                //     text: "Data de criação",
                //     value: "created_at",
                //     sortable: true,
                // },
                {
                    text: "Data do evento",
                    value: "send_date",
                    sortable: false,
                    // align: "center",
                    width: 200,
                },

                {
                    text: "Mensagem",
                    value: "message",
                    sortable: false,
                    // width: 450,
                },
                {
                    text: "Status do boleto",
                    value: "status",
                    sortable: false,
                    align: "center",
                    width: 140,
                    // class: "minWidthCell"
                },
            ],
        }
    },
    props: {
        transactionCode: String,
        callbacksList: Array,
        callbackHistoryDialog: Boolean,
        toogleCallbackHistoryList: Function,
        creditCard: Boolean
    },
    components: {
        TableCreditCard,
    },
}
</script>

<style>
.minWidthCell {
    min-width: 300px;
}

.sendCallback {
    color: #5a77b3;
}
</style>