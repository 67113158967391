var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1440px"},model:{value:(_vm.callbackHistoryDialog),callback:function ($$v) {_vm.callbackHistoryDialog=$$v},expression:"callbackHistoryDialog"}},[_c('v-card',{attrs:{"min-height":"465"}},[_c('v-card-title',{staticClass:"px-2",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"height":"50","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0 pt-0"},[_vm._v(" Histórico de Callbacks ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-6",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleCallbackHistoryList}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"px-16 mt-8 mx-4"},[_c('h3',{staticClass:"mb-2 grey--text text--darken-2 font-weight-medium"},[_vm._v("Lista de callbacks executados")]),_c('p',{staticClass:"mb-8 body-2 grey--text text--darken-3"},[_vm._v(" Acompanhe todo o histórico de callbacks da transção de código - "),_c('b',[_vm._v(_vm._s(_vm.transactionCode))])])]),_c('v-card-text',{staticClass:"px-16"},[(_vm.creditCard ? true : false)?_c('TableCreditCard',{attrs:{"callbacksList":_vm.callbacksList}}):_vm._e(),(_vm.creditCard ? false : true)?_c('v-data-table',{staticClass:"elevation-0 scrollTable",attrs:{"items-per-page":5,"headers":_vm.headers,"items":_vm.callbacksList},scopedSlots:_vm._u([{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[_vm.setColorLine(item.sent, item.send_error)[1], 'pa-1 rounded']},'div',attrs,false),on),[_c('v-icon',{staticClass:"white--text font-weight-medium"},[_vm._v(_vm._s(item.sent ? 'call_made' : 'call_received'))])],1)]}}],null,true)},[_c('span',{},[_vm._v(_vm._s(item.sent ? 'Envio' : 'Resposta'))])])]}},{key:"item.send_date",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.setColorLine(item.sent, item.send_error)[2]},[_vm._v(_vm._s(_vm._f("formatDate")((item.send_date ? item.send_date.split(" ")[0] : item.receipt_date.split(" ")[0])))),_c('span',[_c('v-icon',{staticClass:"ml-3 mb-1 font-weight-black",attrs:{"x-small":""}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(item.send_date ? item.send_date.split(" ")[1] : item.receipt_date.split(" ")[1]))],1)])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.setColorLine(item.sent, item.send_error)[2]},[_vm._v(" "+_vm._s(item.message)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:_vm.setStatusBillet(item.status)[3],attrs:{"color":_vm.setStatusBillet(item.status)[0],"center":""},on:{"click":function($event){_vm.setStatusBillet(item.status)[2].indexOf('Falha') < 0
                                    ? null
                                    : _vm.confirmRetryRegisterBillet(item, $event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.setStatusBillet(item.status)[1])+" ")])]}}],null,true)},[_c('span',{staticClass:"miniTtip",domProps:{"innerHTML":_vm._s(_vm.setStatusBillet(item.status)[2])}})])]}}],null,false,2592500353)}):_vm._e()],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleCallbackHistoryList}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }