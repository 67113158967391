<template>
    <v-row class="px-3 py-5">
        <v-col class="pl-5" cols="3">
            <v-text-field-money class="" v-model="editDataBillet.valorOriginal" v-bind:label="'Valor inicial do boleto'"
                v-bind:properties="{
                    prefix:
                        editDataBillet.valorOriginal && editDataBillet.valorOriginal.length
                            ? 'R$'
                            : '',
                    readonly: true,
                    outlined: false,
                    clearable: false,
                    placeholder: ' ',
                }" v-bind:options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                    empty: null,
                }" />
        </v-col>
        <v-col class="pl-5" cols="3">
            <v-text-field-money class="" v-model="editDataBillet.billing_bank" v-bind:label="'Tarifa do bancária'"
                v-bind:properties="{
                    prefix:
                        editDataBillet.billing_bank && editDataBillet.billing_bank.length
                            ? 'R$'
                            : '',
                    readonly: true,
                    disabled: editDataBillet.billing_bank ? false : true,
                    outlined: false,
                    clearable: false,
                    placeholder: ' ',
                }" v-bind:options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                    empty: null,
                }" />
        </v-col>
        <v-col class="pl-5" cols="3">
            <v-text-field-money class="ajustFontLabel" v-model="editDataBillet.billing_value"
                v-bind:label="'Tarifa Community Ware'" v-bind:properties="{
                    prefix:
                        editDataBillet.billing_value && editDataBillet.billing_value.length
                            ? 'R$'
                            : '',
                    readonly: true,
                    disabled: editDataBillet.billing_value ? false : true,
                    outlined: false,
                    clearable: false,
                    placeholder: ' ',
                }" v-bind:options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                    empty: null,
                }" />
        </v-col>
        <v-col class="pl-5" cols="3">
            <v-text-field-money class="" v-model="editDataBillet.title_liquided.pay_value" v-bind:label="'Valor pago'"
                v-bind:properties="{
                    prefix:
                        editDataBillet.title_liquided.pay_value
                            ? 'R$'
                            : '',
                    readonly: true,
                    disabled: editDataBillet.title_liquided.pay_value ? false : true,
                    outlined: false,
                    clearable: false,
                    placeholder: ' ',
                }" v-bind:options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                    empty: null,
                }" />
        </v-col>


        <v-col class="pl-5" cols="3">
            <v-text-field-money class="" v-model="editDataBillet.valorAbatimento" v-bind:label="'Valor de abatimento'"
                v-bind:properties="{
                    prefix:
                        editDataBillet.valorAbatimento &&
                            editDataBillet.valorAbatimento.length
                            ? 'R$'
                            : '',
                    readonly: true,
                    disabled: editDataBillet.valorAbatimento ? false : true,
                    outlined: false,
                    clearable: false,
                    placeholder: ' ',
                }" v-bind:options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                    empty: null,
                }" />
        </v-col>
        <v-col class="pl-5" cols="2">
            <v-select :disabled="editDataBillet.data_title.indicadorPix ? false : true" readonly class=""
                v-model="editDataBillet.data_title.indicadorPix" :items="utilizaPix" label="Utilizar Pix"
                item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col class="pl-5 pr-0" cols="6">
            <v-text-field v-model="editDataBillet.data_title.linhaDigitavel" label="Linha digitável"
                readonly></v-text-field>
        </v-col>
        <v-col cols="1">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="px-1 mr-3"
                        @click="copyDigLine(editDataBillet.data_title.linhaDigitavel)" fab x-small color="primaryNew"
                        dark><v-icon center small> content_copy </v-icon></v-btn>
                </template>
                <span class="miniTtip">{{ copyed }}</span>
            </v-tooltip>
        </v-col>


        <v-col cols="12" class="py-0">
            <v-card-subtitle class="pl-2 py-0 text-left font-weight-bold">
                Multa
            </v-card-subtitle>
        </v-col>
        <v-col class="pl-5" cols="3">
            <v-select :disabled="editDataBillet.data_title.multa.tipo ? false : true" readonly class=""
                v-model="editDataBillet.data_title.multa.tipo" :items="tipoMulta" label="Tipo de multa" item-text="name"
                item-value="id"></v-select>
        </v-col>
        <v-col class="pl-5 pr-0" cols="3">
            <v-text-field :disabled="multaDataFormat ? false : true" v-model="multaDataFormat" label="Data de aplicação"
                readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pr-0" cols="3">
            <v-text-field suffix="%" :disabled="editDataBillet.data_title.multa.porcentagem ? false : true"
                v-model="editDataBillet.data_title.multa.porcentagem" label="Porcentagem" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pr-5" cols="3">
            <v-text-field :disabled="editDataBillet.data_title.multa.valor ? false : true"
                v-model="editDataBillet.data_title.multa.valor" label="Valor" readonly></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
            <v-card-subtitle class="pl-2 py-0 text-left font-weight-bold">
                Juros
            </v-card-subtitle>
        </v-col>
        <v-col class="pl-5" cols="4">
            <v-select :disabled="editDataBillet.data_title.jurosMora.tipo ? false : true" readonly
                v-model="editDataBillet.data_title.jurosMora.tipo" :items="tipoJuros" label="Tipo de juros"
                item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col class="pl-5 pr-0" cols="4">
            <v-text-field suffix="%" :disabled="editDataBillet.data_title.jurosMora.porcentagem ? false : true"
                v-model="editDataBillet.data_title.jurosMora.porcentagem" label="Porcentagem" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pr-5" cols="4">
            <v-text-field :disabled="editDataBillet.data_title.jurosMora.valor ? false : true"
                v-model="editDataBillet.data_title.jurosMora.valor" label="Valor" readonly></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
            <v-card-subtitle class="pl-2 py-0 text-left font-weight-bold">
                Descontos
            </v-card-subtitle>
        </v-col>
        <v-col class="pl-5" cols="3">
            <v-select :disabled="editDataBillet.data_title.desconto.tipoDesconto ? false : true" readonly
                v-model="editDataBillet.data_title.desconto.tipoDesconto" :items="tipoJuros" label="Tipo de juros"
                item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col class="pl-5 pr-0" cols="3">
            <v-text-field :disabled="discounDate ? false : true" v-model="discounDate" label="Data de validade"
                readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pr-0" cols="3">
            <v-text-field suffix="%" :disabled="editDataBillet.data_title.desconto.porcentagem ? false : true"
                v-model="editDataBillet.data_title.desconto.porcentagem" label="Porcentagem" readonly></v-text-field>
        </v-col>
        <v-col class="pl-5 pr-5" cols="3">
            <v-text-field :disabled="editDataBillet.data_title.desconto.valor ? false : true"
                v-model="editDataBillet.data_title.desconto.valor" label="Valor" readonly></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
export default {
    computed: {
        multaDataFormat() {
            return this.editDataBillet.data_title.multa.data ? this.editDataBillet.data_title.multa.data.replaceAll('.', '/') : ''
        },
        discounDate() {
            return this.editDataBillet.data_title.desconto.dataExpiracao ? this.editDataBillet.data_title.multa.data.replaceAll('.', '/') : ''
        }
    },
    methods: {
        copyedLine() {
            this.copyed = 'Copiada!'
            setTimeout(() => {
                this.copyed = 'Copiar linha digitável'
            }, 2500);
        },
        copyDigLine(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            let _this = this;
            navigator.clipboard.writeText(text).then(
                function () {
                    _this.reveal = true
                    setTimeout(() => {
                        _this.reveal = false
                    }, 2500);
                },
                function (err) {
                    console.error("Async: Could not copy text: ", err);
                }
            );
            this.copyedLine()
        },
    },
    data() {
        return {
            copyed: "Copiar linha digitável",
            utilizaPix: [
                // { id: null, name: "Selecione" },
                { id: "S", name: "Sim" },
                { id: "N", name: "Não" },
            ],
            tipoDesconto: [
                { id: 0, name: "Sem desconto" },
                { id: 1, name: "Valor fixo até a data informada" },
                { id: 2, name: "Percentual até a data informada" },
                { id: 3, name: "Desconto por dia antecipado" },
            ],
            tipoMulta: [
                // { id: null, name: "Selecione" },
                { id: 0, name: "Dispensar" },
                { id: 1, name: "Valor por dia de atraso" },
                { id: 2, name: "Taxa mensal" },
            ],
            tipoJuros: [
                // { id: null, name: "Selecione" },
                { id: 0, name: "Dispensar" },
                { id: 1, name: "Valor por dia de atraso" },
                { id: 2, name: "Taxa mensal" },
            ],
        }
    },
    props: {
        editDataBillet: Object
    },
}
</script>

<style>
.ajustFontLabel label {
    font-size: 15px !important;
}
</style>