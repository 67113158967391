<template>
    <div style="max-width: 1600px; margin: auto; position: relative;">
        <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
            <v-col cols="12" class="pb-0">
                <h4 class="titlePages text-left font-weight-medium mb-4">Transações de boletos
                </h4>
                <p style="max-width: 50%;" class="mb-0 text-body-2 font-weight-light text-left mt-2">
                    Reenvie, cancele, baixe, edite e muito mais. Confira todas as ferramentas disponíveis para você
                    administrar seus boletos bancários.
                </p>
            </v-col>
            <v-col cols="12" class="">
                <ManagerBillets ref="managerBillets" :updateBilletLine="updateBilletLine" :getBillets="getBillets"
                    :billets="billets" :profilesCbos="profilesCbos" :banksProfileRegisterCbo="banksProfileRegisterCbo"
                    :toogleManagerBilletsDialog="toogleManagerBilletsDialog"
                    :managerBilletsDialog="managerBilletsDialog" :totalPages="totalPages" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ManagerBillets from "../../../../components/clients/billets/newManagerBillets/ManagerBilletsPanel.vue";
export default {
    methods: {
        startRequest() {
            this.$store.commit("showLoader", {
                circle: true,
            });
            //console.log("loaders");
            this.getConfiguredBanks(true).then((_) => {
                this.getProfiles(true).then((_) => {
                    let query = {
                        number_bank: "",
                        start_date: "",
                        end_date: "",
                        dataVencimento: "",
                        numeroInscricao: "",
                        email: "",
                        profile_id: "",
                        status: "",
                        batch: "",
                        per_page: 5,
                    };
                    this.getBillets(query, true, true, 1, 5).then((_) => {
                        // this.verifySteps();
                        this.$store.commit("hideLoader");
                    });
                });
            });
        },
        toogleManagerBilletsDialog() {
            this.managerBilletsDialog = !this.managerBilletsDialog;
        },
        async getConfiguredBanks(loader) {
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/user/bank/getUserBank",
                query: null,
                method: "get",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.banksProfileRegisterCbo = [
                    ...response.data.map((obj) => {
                        return {
                            number_bank: obj.bank.number_bank,
                            pg_bank_favoured_id: obj.id,
                            bank_name: obj.bank.bank_name,
                        };
                    }),
                ];
                this.banks = [...response.data];
            });
        },
        async getProfiles(loader) {
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/user/profile/get-all",
                query: null,
                method: "get",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.profilesCbos = [
                    ...response.data.map((prf) => {
                        return { id: prf.id, name: prf.profile_name };
                    }),
                ];
                this.profiles = [...response.data];
            });
        },
        async getBillets(parans, loader, mounted, page, perPage) {
            let dataReturn = null;
            let complementPag = page ? `/?page=${page ? page : 1}` : "";
            this.actualPage = page;
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/get-titles" + complementPag,
                query: { ...parans },
                method: "post",
            };
            obj.query.per_page = perPage ? perPage : this.$refs.managerBillets.perPage;
            //console.log("updateLastBilletsParans");
            this.$store.commit("updateLastBilletsParans", obj.query);
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.billets = [];
                let formatBillets = [];
                // console.log('map')
                formatBillets = [
                    ...response.data.data.map((billet) => {
                        let payer = { ...billet.data_title.pagador };
                        let data = { ...billet.data_title };

                        // delete billet.data_title;
                        // delete billet.desconto;
                        // delete billet.desconto;

                        delete data.desconto;
                        delete data.jurosMora;
                        delete data.multa;
                        delete data.pagador;
                        let obj = { ...billet, ...data, ...payer };
                        // console.log("montado",obj)
                        return obj;
                    }),
                ];
                // console.log("array",formatBillets)
                this.billets = [...formatBillets];
                if (mounted) {
                    let arr = [0, 0, 0, 0]
                    response.data.data.map(blt => {
                        switch (blt.status) {
                            case "Liquidado":
                                arr[0]++
                                return;
                            case "Aberto":
                                arr[1]++
                                return;
                            case "Andamento":
                                arr[2]++
                                return;
                            case "Vencido":
                                arr[3]++
                                return;
                        }
                    })
                    this.statusBillets = [...arr]
                }
                dataReturn = response.data
            });
            // console.log('return bolet')
            this.totalPages = dataReturn.last_page;
            // console.log('refs')
            this.$refs.managerBillets.actualPage = dataReturn.current_page;
            // this.actualPage = dataReturn.current_page;
            return dataReturn
        },
        updateBilletLine(id, status) {
            let index = this.billets.findIndex((billet) => billet.id == id);
            this.billets[index].status = status;
        },
    },
    data() {
        return {
            totalPages: 0,
            actualPage: 0,
            managerBilletsDialog: false,
            banksProfileRegisterCbo: [],
            profilesCbos: [],
            billets: [],
            statusBillets: [0, 0, 0, 0]
        }
    },
    mounted() {
        this.startRequest();
    },
    components: {
        ManagerBillets,
    },
}
</script>

<style lang="scss" scoped></style>