<template>
  <v-dialog v-model="registerBilletDilog" scrollable persistent max-width="1200px">
    <v-card min-height="465">
      <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2"><v-app-bar height="50" flat
          color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            {{ editDataBillet ? "Detalhes do boleto" : "Novo boleto" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-fab-transition> -->
          <v-btn @click="toogleRegisterBilletDilog" class="mt-6" color="red" fab icon dark x-small absolute top right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- </v-fab-transition> -->
        </v-app-bar>
      </v-card-title>
      <v-tabs color="primaryNew" class="px-6" v-model="tab">
        <v-tab :key="0">Dados do boleto</v-tab>
        <v-tab v-if="editDataBillet" :key="1">Detalhes de cobrança</v-tab>
        <v-tab :key="2">Endereço</v-tab>
      </v-tabs>
      <v-card-text>
        <v-form ref="billetForm" style="width: 100%">
          <v-tabs-items class="py-5" v-model="tab" style="min-height: 350px; border-top: 1px solid #ccc">
            <v-tab-item v-if="editDataBillet" :key="0">
              <BilletsDetails :editDataBillet="editDataBillet" />
            </v-tab-item>
            <v-tab-item v-if="!editDataBillet" :key="0">
              <v-row class="px-3 py-0">
                <v-col class="pl-5" cols="4">
                  <v-text-field class="" v-model="formData.nome" label="Nome do sacado"></v-text-field>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field class="requiredField" :rules="$store.state.formRules.emailRules"
                    v-model="formData.email" label="E-mail do sacado"></v-text-field>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <!-- hint="Digite o nome do usuário"
                persistent-hint -->
                  <v-select @change="getProfileData" class="requiredField" :rules="$store.state.formRules.required"
                    clearable v-model="formData.profile_name" :items="profilesCbos" label="Selecione umprefil"
                    item-text="name" item-value="name"></v-select>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field-money class="requiredField" v-model="formData.valorOriginal"
                    v-bind:label="'Valor do boleto'" v-bind:properties="{
                      rules: $store.state.formRules.required,
                      prefix:
                        formData.valorOriginal && formData.valorOriginal.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field-money class="specialInfo" v-model="formData.valorAbatimento"
                    v-bind:label="'Valor de abatimento'" v-bind:properties="{
                      prefix:
                        formData.valorAbatimento &&
                          formData.valorAbatimento.length
                          ? 'R$'
                          : '',
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }" v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }" />
                </v-col>
                <v-col cols="4" class="mt-0 pr-5">
                  <v-menu v-model="dataEmissaoMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field clearable v-model="dataEmissaoFormated" label="Data de emissão" readonly
                        v-on="on"></v-text-field>
                      <!-- hint=" Define uma data de expiração para o desconto."
                    persistent-hint -->
                    </template>
                    <v-date-picker no-title v-model="formData.dataEmissao"
                      @input="dataEmissaoMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="mt-0 pr-5">
                  <v-menu v-model="dataVencimentoMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field class="requiredField" :rules="$store.state.formRules.required" clearable
                        v-model="dataVencimentoFormated" label="Data de vencimento" readonly v-on="on"></v-text-field>
                      <!-- hint=" Define uma data de expiração para o desconto."
                    persistent-hint -->
                    </template>
                    <v-date-picker no-title v-model="formData.dataVencimento"
                      @input="dataVencimentoMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <!-- hint="Digite o nome do usuário"
                persistent-hint -->
                  <v-select class="requiredField" :rules="$store.state.formRules.required" clearable
                    v-model="formData.tipoInscricao" :items="$store.state.billetsCbos.tipoInscricao"
                    label="Selecione o tipo de inscrição" item-text="name" item-value="id"></v-select>
                </v-col>

                <v-col cols="4">
                  <div>
                    <!-- v-bind:hint="`Para o tipo = CPF, informar numero do CPF e para o tipo = CNPJ, informar numero do CNPJ`"
                      v-bind:persistent-hint="true" -->
                    <v-text-field-simplemask class="requiredField" v-model="formData.numeroInscricao"
                      v-bind:label="`Número de inscrição do pagador`" v-bind:properties="{
                        rules: [
                          $store.state.formRules.cpfCnpjRules[0](
                            formData.numeroInscricao
                              ? formData.numeroInscricao.toString()
                              : ''
                          ),
                        ],
                        hint: 'Para o tipo = CPF, informar numero do CPF e para o tipo = CNPJ, informar numero do CNPJ',
                        'persistent-hint': true,
                        prefix: '',
                        suffix: '',
                        readonly: false,
                        disabled: false,
                        outlined: false,
                        clearable: false,
                        placeholder: '',
                      }" v-bind:options="{
                        inputMask:
                          (formData.numeroInscricao
                            ? formData.numeroInscricao.length
                            : '') <= 11
                            ? '###.###.###-########'
                            : '##.###.###/####-##',
                        outputMask: '##############',
                        empty: null,
                        applyAfter: false,
                        alphanumeric: true,
                        lowerCase: false,
                      }" />
                  </div>
                </v-col>

                <!-- <v-col class="pl-5" cols="4">
                  <v-text-field
                    class="requiredField"
                    v-model="formData.numeroInscricao"
                    label="Número de inscrição do pagador"
                    hint="Para o tipo = CPF, informar numero do CPF e para o tipo = CNPJ, informar numero do CNPJ"
                    persistent-hint
                  ></v-text-field>
                </v-col> -->
                <v-col class="pl-5" cols="4">
                  <!-- hint="Digite o nome do usuário"
                persistent-hint -->
                  <v-select class="specialInfo" clearable v-model="formData.codigoTipoTitulo"
                    :items="$store.state.billetsCbos.registrationTypes" label="Selecione tipo do título"
                    item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-select hint="Identifica se o boleto de cobrança foi aceito (reconhecimento da dívida pelo Pagador)"
                    persistent-hint class="specialInfo" clearable v-model="formData.codigoAceite"
                    :items="$store.state.billetsCbos.aceite" label="Definição de aceite" item-text="name"
                    item-value="id"></v-select>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field class="" v-model="formData.numeroTituloBeneficiario" label="Título do beneficiário"
                    hint="Número de identificação do boleto (correspondente a um NÚMERO de seu controle)"
                    persistent-hint></v-text-field>
                </v-col>
                <v-col class="pl-5 colBoxLeft" cols="4">
                  <v-textarea maxlength="75" counter="75" rows="1" class="" v-model="formData.linha_descritiva1"
                    label="Linha de descritiva 01" hint="Linha 1 para informaçoes adicionais"
                    persistent-hint></v-textarea>
                </v-col>
                <v-col class="pl-5 colBoxRight" cols="2">
                  <v-select class="" clearable v-model="formData.pos_linha1" :items="positionsLines" label="Posição"
                    item-text="text" item-value="id" hint="Define onde a linha deve aparecer"
                    persistent-hint></v-select>
                </v-col>
                <v-col class="pl-5 colBoxLeft" cols="4">
                  <v-textarea maxlength="75" counter="75" rows="1" class="" v-model="formData.linha_descritiva2"
                    label="Linha de descritiva 02" hint="Linha 2 para informaçoes adicionais"
                    persistent-hint></v-textarea>
                </v-col>
                <v-col class="pl-5 colBoxRight" cols="2">
                  <v-select class="" clearable v-model="formData.pos_linha2" :items="positionsLines" label="Posição"
                    item-text="text" item-value="id" hint="Define onde a linha deve aparecer"
                    persistent-hint></v-select>
                </v-col>
                <v-col class="pl-5 colBoxLeft" cols="4">
                  <v-textarea maxlength="75" counter="75" rows="1" class="" v-model="formData.linha_descritiva3"
                    label="Linha de descritiva 03" hint="Linha 3 para informaçoes adicionais"
                    persistent-hint></v-textarea>
                </v-col>
                <v-col class="pl-5 colBoxRight" cols="2">

                  <v-select class="" clearable v-model="formData.pos_linha3" :items="positionsLines" label="Posição"
                    item-text="text" item-value="id" hint="Define onde a linha deve aparecer"
                    persistent-hint></v-select>
                </v-col>
                <v-col class="pl-6 colBox" cols="6">
                  <v-textarea rows="1" type="" class="specialInfo" v-model="formData.descricaoTipoTitulo"
                    label="Breve descrição sobre o Tipo do título"></v-textarea>
                  <!-- hint="Quantos dias o boleto ficará disponível para pagamento após seu vencimento."
                persistent-hint -->
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="editDataBillet" :key="1">
              <BillingDetails :editDataBillet="editDataBillet" />
            </v-tab-item>
            <v-tab-item :key="2">
              <v-row class="px-3 py-5">
                <v-col class="pl-5" cols="4">
                  <v-text-field :readonly="editDataBillet ? true : false" class="" v-model="formData.endereco"
                    label="Endereço"></v-text-field>
                  <!-- hint="Número de identificação do boleto (correspondente ao SEU NÚMERO)"
                    persistent-hint -->
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field :readonly="editDataBillet ? true : false" class="" v-model="formData.bairro"
                    label="Bairro"></v-text-field>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field :readonly="editDataBillet ? true : false" class="" v-model="formData.cep"
                    label="CEP"></v-text-field>
                </v-col>

                <v-col class="pl-5" cols="4">
                  <v-text-field :readonly="editDataBillet ? true : false" class="" v-model="formData.cidade"
                    label="Cidade"></v-text-field>
                </v-col>
                <v-col class="" cols="4">
                  <!-- :hint="`${select.state}, ${select.abbr}`" -->
                  <!-- :rules="$store.state.formRules.required" -->
                  <v-select :readonly="editDataBillet ? true : false" light v-model="formData.state"
                    :items="$store.state.ufBrasil.UF" item-text="state" item-value="state" label="UF"></v-select>
                </v-col>
                <v-col class="pl-5" cols="4">
                  <v-text-field :readonly="editDataBillet ? true : false" class="" v-model="formData.telefone"
                    label="Telefone"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
        <p v-if="!editDataBillet" style="font-size: 13px; margin-top: -25px;" class="mb-0 pl-2"><small><b
              style="color: rgb(59, 137, 255);">Obs:</b>
            Os campos com <span style="font-size: 15px; color: rgb(59, 137, 255);">✦</span>
            são preenchidos com os dados do perfil, mas você pode
            altera-los em cada boleto.</small></p>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleRegisterBilletDilog">
          Fechar
        </v-btn>
        <v-btn v-if="!editDataBillet" color="green" text @click="saveBillet"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const resetBilletForm = {
  source: "web",
  nome: "",
  email: "",
  valorOriginal: "",
  valorAbatimento: "",
  dataVencimento: "",
  dataEmissao: "",
  tipoInscricao: "",
  numeroInscricao: "",
  codigoTipoTitulo: "",
  codigoAceite: "",
  numeroTituloBeneficiario: "",
  descricaoTipoTitulo: "",
  endereco: "",
  cidade: "",
  bairro: "",
  cep: "",
  telefone: "",
  linha_descritiva1: "",
  linha_descritiva2: "",
  linha_descritiva3: "",
  pos_linha1: "",
  pos_linha2: "",
  pos_linha3: ""
};
import * as moment from "moment";
import BilletsDetails from "./BilletsDetails.vue"
import BillingDetails from "./BillingDetails.vue"
export default {
  methods: {
    getProfileData() {
      if (!this.formData.profile_name) {
        return
      }
      let index = this.profilesCbos.findIndex(
        (profile) => profile.name === this.formData.profile_name
      );
      this.formData.profile_id = this.profilesCbos[index].id
      var obj = {
        url: "/api/v1/title/user/profile/getById",
        query: { id: this.profilesCbos[index].id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        if (response.data[0].profile_data.valorAbatimento) {
          this.formData.valorAbatimento = response.data[0].profile_data.valorAbatimento
        }
        if (response.data[0].profile_data.codigoTipoTitulo) {
          this.formData.codigoTipoTitulo = response.data[0].profile_data.codigoTipoTitulo
        }
        if (response.data[0].profile_data.codigoAceite) {
          this.formData.codigoAceite = response.data[0].profile_data.codigoAceite
        }
        if (response.data[0].profile_data.descricaoTipoTitulo) {
          this.formData.descricaoTipoTitulo = response.data[0].profile_data.descricaoTipoTitulo
        }
      })
      //console.log('ooo', obj.query.id)
    },
    saveBillet() {
      if (!this.$refs.billetForm.validate()) {
        return;
      }
      var obj = {
        url: this.formData.id ? "/api/v1/title/update" : "/api/v1/title/create",
        query: this.formData,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        if (!response) {
          return;
        }
        let opts = {
          message: `Boleto ${this.formData.id ? "atualizado" : "criado"
            } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
        this.toogleRegisterBilletDilog();
        let query = {
          number_bank: "",
          start_date: "",
          end_date: "",
          dataVencimento: "",
          numeroInscricao: "",
          email: "",
          profile_name: "",
          status: "",
          batch: "",
        };
        this.getBillets(query).then((_) => {
          //this.verifySteps();
        });
      });
    },
  },
  watch: {
    registerBilletDilog(v) {
      if (v) {
        if (this.editDataBillet) {
          this.formData = { ...this.editDataBillet };
          let index = this.profilesCbos.findIndex(
            (profile) => profile.id === this.formData.profile_id
          );
          this.formData.profile_name = this.profilesCbos[index].name
          this.getProfileData()
        }
        if (this.profilesCbos.length == 1) {
          this.formData.profile_name = this.profilesCbos[0].name
          setTimeout(() => {
            this.getProfileData()
          }, 10);
        }
      } else {
        this.tab = 0
        this.$refs.billetForm.resetValidation();
        this.formData = { ...resetBilletForm };
      }
    },
  },
  data() {
    return {
      positionsLines: [
        { id: 1, text: "Recibo do pagador" },
        { id: 2, text: "Ficha de compensação" },
        { id: 3, text: "Ambos" },
        { id: 4, text: "Não visualizar no boleto" },
      ],
      tab: null,
      dataVencimentoMenu: false,
      dataEmissaoMenu: false,
      formData: { ...resetBilletForm },
    };
  },
  computed: {
    dataVencimentoFormated: {
      get: function () {
        let date = this.formData.dataVencimento
          ? moment(this.formData.dataVencimento).format("DD/MM/YYYY")
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.dataVencimento).format("DD/MM/YYYY");
        // console.log("aaaa", newValue);
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.dataVencimento = newValue;
        }
        return newValue;
      },
    },
    dataEmissaoFormated: {
      get: function () {
        let date = this.formData.dataEmissao
          ? moment(this.formData.dataEmissao).format("DD/MM/YYYY")
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.dataEmissao).format("DD/MM/YYYY");
        // console.log("aaaa", newValue);
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.dataEmissao = newValue;
        }
        return newValue;
      },
    },
  },
  props: {
    editDataBillet: Object,
    profilesCbos: Array,
    toogleRegisterBilletDilog: Function,
    registerBilletDilog: Boolean,
    getBillets: Function,
    verifySteps: Function,
  },
  mounted() {
    //this.getProfiles();
  },
  components: {
    BilletsDetails,
    BillingDetails
  },
};
</script>

<style>
.colBoxLeft {
  border-left: 5px solid #fff;
  border-radius: 8px 0 0 8px;
  background: #f4f8ff;
  margin-top: 10px;
}

.colBoxRight {
  margin-top: 10px;
  border-right: 5px solid #fff;
  border-radius: 0 8px 8px 0;
  background: #f4f8ff;
}

.colBox {
  margin-top: 10px;
  border-right: 5px solid #fff;
  border-left: 5px solid #fff;
  border-radius: 8px;
  background: #f4f8ff;
}
</style>