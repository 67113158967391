<template>
  <v-row justify="center">
    <v-dialog max-width="1280px" v-model="listBatchsDialog" persistent transition="dialog-top-transition">
      <v-card>
        <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 mb-5"><v-app-bar height="50" flat
            color="rgba(0, 0, 0, 0)">
            <v-toolbar-title class="text-h5 pl-0 pt-0">
              Gerenciamento de lotes enviados
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-fab-transition> -->
            <v-btn @click="toogleStatusBatchDialog" class="mt-6" color="red" fab icon dark x-small absolute top right>
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- </v-fab-transition> -->
          </v-app-bar></v-card-title>
        <v-card-title class="pt-5 pb-3 px-10 blue-grey--text text--darken-2">
          <v-container fluid class="pb-4">
            <v-row class="">
              <v-col class="py-0" cols="4">
                <v-text-field clearable class="" v-model="batch" label="Nome do lote"></v-text-field>
              </v-col>
              <v-col class="py-0" cols="4">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="getBatchs()" v-bind="attrs" v-on="on" class="mx-2" fab dark small color="primary">
                      <v-icon dark small> search </v-icon>
                    </v-btn>
                  </template>
                  <span>Procurar pelo nome do lote</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="mb-7 mt-0">
              <v-col class="py-0" cols="12">
                <div style="word-break: normal" class="body-2 grey--text text--darken-2">
                  Procure lotes por nome e acompanhe a situação do processamento
                  de registro deles.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <div class="px-12">
          <v-data-table :headers="headers" :items="batchs" class="elevation-0 scrollTable">
            <template v-slot:item.titles_processeds="{ item }">
              <td>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
              <td class="pl-1">
                <!-- v-if="buildTotal(item)[0] < 100" -->
                <v-progress-circular v-bind="attrs" v-on="on" :rotate="360" :size="40" :width="7"
                  :value="buildTotal(item)[0]" :color="buildTotal(item)[1]">
                  {{
                    buildTotal(item)[0] == 100
                      ? "✔"
                      : item.titles_processeds
                  }}
                </v-progress-circular>
              </td>
            </template>
            <span class="miniTtip">{{ buildTotal(item)[2] }} </span>
            </v-tooltip>
            </td>
</template>
<template v-slot:item.created_at="{ item }">
  <td class="px-1">
    {{ item.created_at.substring(0, 10) | formatDate }}
  </td>
</template>
<template v-slot:item.actions="{ item }">
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="btMicro px-1 mr-1" @click="getBatchById(item)" x-small
        color="teal darken-2" dark><v-icon center x-small> remove_red_eye </v-icon></v-btn>
    </template>
    <span class="miniTtip">Ver detalhes do lote</span>
  </v-tooltip>
</template>
</v-data-table>
</div>
<v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
  <v-spacer></v-spacer>
  <v-btn color="red" text @click="toogleStatusBatchDialog"> Fechar </v-btn>
  <!-- <v-btn color="green" text @click="sendBatch"> Salvar </v-btn> -->
</v-card-actions>
</v-card>
</v-dialog>
<BatchDetails :billets="billets" :batchName="batchName" :batchDetailDialog="batchDetailDialog"
  :toogleBatchDetailDilog="toogleBatchDetailDilog" />
</v-row>
</template>

<script>
import MyFilters from "../../../../filters/genericFilters";
import BatchDetails from "./BatchDetails.vue";
export default {
  watch: {
    listBatchsDialog(v) {
      if (v) {
        this.getBatchs();
      }
    },
  },
  methods: {
    getBatchById(data) {
      let obj = {
        url: "/api/v1/user/batch/getById",
        query: { id: data.id },
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        let formatBillets = [
          ...response.data["titles"].map((billet) => {
            //billet.data_title = JSON.parse(billet.data_title);
            let payer = { ...billet.data_title.pagador };
            let data = { ...billet.data_title };

            delete data.desconto;
            delete data.jurosMora;
            delete data.multa;
            delete data.pagador;
            let obj = { ...billet, ...data, ...payer };

            // console.log("montado",obj)

            return obj;
          }),
        ];
        console.log("array", formatBillets);
        this.billets = [...formatBillets];
        // this.billets = [...response.data["titles"][0].titles]
      });
      this.toogleBatchDetailDilog(data.name);
    },
    toogleBatchDetailDilog(name) {
      if (name) {
        this.batchName = name;
      } else {
        this.batchName = "";
      }
      this.batchDetailDialog = !this.batchDetailDialog;
      if (!this.batchDetailDialog) {
        this.billets = [];
      }
    },
    buildTotal(data) {
      let percent = "";
      let color = "";
      let tooltip = "";
      percent = (data.titles_processeds * 100) / data.titles_quantity;
      if (percent < 40) {
        color = "red darken-2";
      } else if (percent >= 40 && percent < 90) {
        color = "amber accent-4";
      } else {
        color = "teal darken-2";
      }
      tooltip =
        percent < 100
          ? `${data.titles_processeds} de um total de ${data.titles_quantity}`
          : "Processamento concluído";

      return [parseInt(percent), color, tooltip];
    },
    getBatchs() {
      let query = {
        batch_name: this.batch,
      };
      let obj = {
        url: "/api/v1/user/batch/get-all",
        query: query,
        method: "post",
      };
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        //console.log("lotes", response.data);
        this.batchs = [...response.data];
        // this.batchs.push({
        //   id: 222,
        //   name: "Lote Teste 323",
        //   user_id: 4,
        //   profile_id: 2,
        //   titles_quantity: 35,
        //   titles_processeds: 10,
        //   persist_exception: 0,
        //   register_exception: 0,
        //   status: "Registrando",
        //   created_at: "2023-01-27T21:49:53.000000Z",
        //   updated_at: "2023-01-31T16:08:35.000000Z",
        // });
      });
    },
  },
  data() {
    return {
      batchName: "",
      batchDetailDialog: false,
      batch: "",
      headers: [
        {
          text: "Processados",
          value: "titles_processeds",
          sortable: false,
        },
        {
          text: "Nome do lote",
          value: "name",
          sortable: true,
        },
        {
          text: "Qtd. de falhas",
          value: "register_exception",
          sortable: false,
          align: "center",
        },
        {
          text: "Data de criação",
          value: "created_at",
          sortable: true,
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      batchs: [],
      billets: [],
    };
  },
  props: {
    listBatchsDialog: Boolean,
    toogleStatusBatchDialog: Function,
  },
  components: {
    BatchDetails,
  },
};
</script>

<style></style>