<template>
  <v-container fluid class="pb-4 bordBottom">
    <v-row class="pl-4">

      <v-col class="pt-0" cols="2">
        <v-text-field class="" v-model="formData.payer_name" label="Nome do sacado"></v-text-field>
      </v-col>


      <v-col class="py-0" cols="2">
        <v-text-field clearable class="" v-model="formData.lote" label="Nome do lote"></v-text-field>
      </v-col>
      <v-col class="py-0" cols="3">
        <v-combobox class="" clearable v-model="formData.bank" :items="banks" label="selecione um banco"
          item-text="bank_name" item-value="number_bank"></v-combobox>
      </v-col>
      <v-col class="py-0" cols="2">
        <v-combobox class="" clearable v-model="formData.profile" :items="profilesCbos" label="Selecione perfil"
          item-text="name" item-value="id"></v-combobox>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-menu v-model="dataExpiracaoMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable v-model="dataExpiracaoFormated" label="Vencimento" readonly
              v-on="on"></v-text-field>
            <!-- hint=" Define uma data de expiração para o desconto."
            persistent-hint -->
          </template>
          <v-date-picker no-title v-model="formData.dataExpiracao" @input="dataExpiracaoMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" class="py-0 pl-3 text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="search('')" v-bind="attrs" v-on="on" class="mx-2" fab dark small color="primary">
              <v-icon dark small> search </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="pl-4 mt-0" v-show="moreFilters">
      <v-col cols="2" class="py-0">
        <v-menu ref="calendar" v-model="calendar" :close-on-content-click="false" transition="scale-transition"
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field clearable class="rangeInputDate subtitle-2" v-model="dateRangeText" label="Definir período"
              v-bind="attrs" v-on="on"></v-text-field>
            <!-- hint="Selecione o primeiro e o último dia"
              persistent-hint -->
          </template>
          <v-date-picker class="rangeMode" range v-model="dates" no-title>
            <div style="width: 100%" class="bordTop d-flex flex-row justify-start">
              <v-btn class="mt-2" text color="primary" @click="calendar = false">
                OK
              </v-btn>
              <v-btn class="mt-2" text color="primary" @click="calendar = false">
                Cancelar
              </v-btn>
            </div>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="pt-0" cols="2">
        <v-text-field class="" v-model="formData.transaction_code" label="Código da transação"></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="2">
        <v-text-field class="" v-model="formData.numeroInscricao" label="Documento"></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="2">
        <v-text-field class="" v-model="formData.email" label="E-mail"></v-text-field>
      </v-col>
    </v-row>
    <div class="pl-4 pr-2 d-flex flex-row pt-3">
      <v-btn @click="search('Vencido')" x-small color="red lighten-1" dark class="mr-2 py-3">
        Vencidos <v-icon small right> event_busy </v-icon>
      </v-btn>
      <v-btn @click="search('Andamento')" class="mx-2 py-3" x-small color="orange darken-2" dark>
        Aguardando registro <v-icon small right> query_builder </v-icon>
      </v-btn>
      <v-btn @click="search('Aberto')" class="mx-2 py-3" x-small color="blue darken-1" dark>
        Registrados <v-icon small right> verified </v-icon>
      </v-btn>
      <v-btn @click="search('Liquidado')" class="mx-2 py-3" x-small color="green darken-3" dark>
        Liquidados <v-icon small right> attach_money </v-icon>
      </v-btn>
      <!-- <v-spacer></v-spacer> -->

      <!-- search(['Falha', 'Falha no Registro', 'Falha na Baixa/Cancelamento']) -->
      <v-btn @click="search('Falha')" class="mx-2 py-3" x-small color="red darken-4" dark>
        Falhas <v-icon small right> new_releases </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="toogleBilletsLogDilog" class="mx-0 py-3" small color="deep-orange darken-3" dark>
        <v-icon small left> integration_instructions </v-icon>Logs
      </v-btn>
      <BilletsLogs :billetsLogDilog="billetsLogDilog" :toogleBilletsLogDilog="toogleBilletsLogDilog" />
    </div>
  </v-container>
</template>

<script>
const restFilters = {
  transaction_code: "",
  payer_name: "",
  numeroInscricao: "",
  email: "",
  profile: "",
  dataExpiracao: "",
};
import * as moment from "moment";
import BilletsLogs from "../../billets/managerBillets/BilletsLogs.vue"
export default {
  methods: {
    toogleBilletsLogDilog() {
      this.billetsLogDilog = !this.billetsLogDilog
    },
    search(statu) {
      this.formData.startDate = this.dates[0];
      this.formData.endDate = this.dates[1];
      //console.log(this.formData);
      //return;
      let query = {
        number_bank: this.formData.bank ? this.formData.bank.number_bank : "",
        start_date: this.dates[0],
        end_date: this.dates[1],
        dataVencimento: this.formData.dataExpiracao,
        numeroInscricao: this.formData.numeroInscricao,
        email: this.formData.email,
        profile_id: this.formData.profile ? this.formData.profile.id : "",
        status: statu,
        batch: this.formData.lote,
        payer_name: this.formData.payer_name,
        transaction_code: this.formData.transaction_code
      };
      this.getBillets(query, false, false, 1);
    },
    formatRangeDate(listDates) {
      let returnDate = "";
      for (let i = 0; i < listDates.length; i++) {
        let [year, month, day] = listDates[i].split("-");
        returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
      }

      return returnDate;
    },
  },
  watch: {
    moreFilters(v) {
      if (!v) {
        this.formData.transaction_code = "";
        this.formData.numeroInscricao = "";
        this.formData.email = "";
        this.dates = []
      }
    },
  },
  data() {
    return {
      billetsLogDilog: false,
      dates: [],
      calendar: false,
      dataExpiracaoMenu: false,
      formData: { ...restFilters },
    };
  },
  computed: {
    dateRangeText: {
      // getter
      get: function () {
        return this.formatRangeDate(this.dates);
      },
      // setter
      set: function (newValue) {
        if (!newValue) {
          this.dates = [];
        }
      },
    },
    dataExpiracaoFormated: {
      get: function () {
        let date = this.formData.dataExpiracao
          ? moment(this.formData.dataExpiracao).format("DD/MM/YYYY")
          : "";
        return date == "Invalid date" ? null : date;
      },
      // setter
      set: function (newValue) {
        let date = moment(this.formData.dataExpiracao).format("DD/MM/YYYY");
        //console.log("aaaa", newValue);
        if (date == "Invalid date") {
          return null;
        }
        if (!newValue) {
          this.formData.dataExpiracao = newValue;
        }
        return newValue;
      },
    },
  },
  props: {
    profilesCbos: Array,
    banks: Array,
    moreFilters: Boolean,
    getBillets: Function,
  },
  components: {
    BilletsLogs,
  },
};
</script>

<style></style>